import React, { useEffect, useState } from "react";
import { Input, Button, Avatar, Space, message } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import "./Forum.scss";
import CustomButton from "../CustomButton/CustomButton";
import { GetFAQAPI, PostFAQApi } from "../../api's/Api";
import { Container } from "react-bootstrap";const Forum = () => {
  const [question, setQuestion] = useState("");
  const [faqs,setFaqs] = useState([])
const token = localStorage.getItem("userToken")

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const headers = {
    "x-access-token":token,
    role_id:6

  }
 
    const handlePostFaq = () => {
      if (question.trim()) {
        PostFAQApi({ question }, headers)
          .then((res) => {
            console.log("res", res);
            message.success("Question posted successfully!");
            setQuestion("");
            loadFaqs(); // Reload FAQs after posting a new question
          })
          .catch((e) => {
            console.log("error", e);
            message.error("Failed to post the question.");
          });
      } else {
        console.log("Question is empty");
        message.warning("Please enter a question before submitting.");
      }
    };


  const loadFaqs = () => {
    GetFAQAPI()
      .then((res) => {
        console.log("res", res.data.data);
        setFaqs(res?.data?.data);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  useEffect(() => {
    loadFaqs();
  }, []);

  return (
    <Container>
      
    <div className="forum-container">
      <div className="forum-content">
        <h1>Forum</h1>
        <div style={{border:"1px solid #e8e8e8",padding:'24px',marginBottom:"20px"}}>
        <div className="question-form">
          <Avatar
            src="https://via.placeholder.com/40"
            className="question-avatar"
          />
          <Input
             placeholder="Type your question here..."
             className="question-input"
             value={question}
             onChange={handleInputChange}
          />
        </div>
        <CustomButton text="Ask Question" width="130px" padding="5px" margin="10px 50px" onClick={handlePostFaq}/>

        </div>
        {faqs.map((item) => (
          <div key={item.id} className="forum-item">
            <div className="forum-item-meta">
              <Avatar className="forum-item-avatar">
                {item.forum_user.user_profile.name.charAt(0).toUpperCase()}
              </Avatar>
              <div className="forum-item-info">
                <h2>{item.forum_user.user_profile.name}</h2>
                <p>{item.question}</p>
                <div className="forum-item-content">{item.answer}</div>
                <Space className="forum-item-actions">
                  <span>
                    <MessageOutlined /> {item.comments ? item.comments.length : 0} Comments
                  </span>
                  <span>{new Date(item.created_at).toLocaleDateString()}</span>
                </Space>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </Container>
    
  );
};

export default Forum;
