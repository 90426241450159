import React from "react";
import { Modal, Table } from "antd";
import CustomButton from "../CustomButton/CustomButton";
import "./CourseOngoing.scss";

const Result = ({
  isVisible,
  onClose,
  quizResults, // Expecting an array with one object
  restartQuiz,
  handleContinue,
}) => {
  // Table columns for quiz results
  const columns = [
    {
      title: "Total Questions",
      dataIndex: "Total Questions", // Key from the quizResults object
      key: "totalQuestions",
    },
    {
      title: "Total Score",
      dataIndex: "Total Score", // Key from the quizResults object
      key: "totalScore",
    },
    {
      title: "Correct Answers",
      dataIndex: "Correct Answers", // Key from the quizResults object
      key: "correctAnswers",
    },
    {
      title: "Wrong Answers",
      dataIndex: "Wrong Answers", // Key from the quizResults object
      key: "wrongAnswers",
    },
    {
      title: "User Score",
      dataIndex: "User Score", // Key from the quizResults object
      key: "userScore",
    },
  ];

  // Since the result is already an object in an array, just use it directly
  const dataSource = quizResults.map((result, index) => ({
    key: index,
    ...result,
  }));

  return (
    <Modal
      title="Scoreboard"
      visible={isVisible}
      onCancel={onClose}
      style={{ width: "700px" }}
      footer={[
        <div className="quizResultContainer">
          <CustomButton
            key="restart"
            text="Restart Quiz"
            onClick={restartQuiz}
          />
          ,
          <span style={{ margin: "0 8px" }} />,
          <CustomButton key="done" text="Done" onClick={handleContinue} />,
        </div>,
      ]}
    >
      {/* Quiz result table */}
      <Table
        dataSource={dataSource} // Data source for the table
        columns={columns} // Columns definition
        pagination={false} // Disable pagination
        bordered // Add borders around table cells
      />
    </Modal>
  );
};

export default Result;
