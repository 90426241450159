import React from "react";
import { Row, Col, Form, Input, message, Button } from "antd";
import { Container } from "react-bootstrap";
import { ContactUsAPI } from "../../api's/Api";
import "./ContactUs.scss";

const ContactUs = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const token = localStorage.getItem("userToken");

  const onFinish = (values) => {
    const headers = {
      "x-access-token": token,
    };

    const formData = {
      name: values.fullname,
      email: values.email,
      contact_number: values.phone,
      help_topic: values.help,
      enquiry_details: values.inquiry,
    };

    ContactUsAPI(formData, headers)
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.message);
          form.resetFields();
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        message.error("There was an error submitting your inquiry.");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="contact-us">
      <div className="contactBanner">
        <Container>
          <h1 className="heading">Contact Us</h1>
          <p className="text">
            A better training experience. Designed by gauge operators and
            regulatory licencing specialists, for gauge operators and RSOs.
          </p>
        </Container>
      </div>
      <Container>
        <Row>
          <Col span={12} className="left-side">
            <h4>How can we help you?</h4>
            <p>
              New to Nuclear Gauging? Need a quick refresher? our Infographic &
              Get a Fast Overview of What You Require for Regulatory Compliance
              including Licence Application, Training, Transportation of Nuclear
              Gauges, Audits & Inspections
            </p>
            <p>
              New to Nuclear Gauging? Need a quick refresher? our Infographic &
              Get a Fast Overview of What You Require for Regulatory Compliance.
            </p>
          </Col>
          <Col span={12} className="right-side">
            <Form
              form={form}
              name="contact"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                label="Full Name"
                name="fullname"
                rules={[
                  { required: true, message: "Please input your full name!" },
                ]}
              >
                <Input placeholder="Full Name" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input placeholder="Phone Number" />
              </Form.Item>
              <Form.Item
                label="What is your inquiry about?"
                name="inquiry"
                rules={[
                  { required: true, message: "Please input your inquiry!" },
                ]}
              >
                <Input placeholder="What is your inquiry about?" />
              </Form.Item>
              <Form.Item
                label="How can we help you?"
                name="help"
                rules={[
                  {
                    required: true,
                    message: "Please input how we can help you!",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="How can we help you?" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="button" className="submit-btn">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
