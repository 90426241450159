// reducers/index.js
import { combineReducers } from 'redux';
import cartReducer from './addToCartSlice'
import reviewRatingReducer from "./reviweAndRatinSlice"
import profileSlice from './profileSlice';
// import courseReducer from "./courseSlice";
// import ratingReducer from "./ratingSlice";
// import userReducer from "./UserProfileSlice"
const rootReducer = combineReducers({
  
  cartReducer,
  reviewRatingReducer,
  profileSlice
//   navReducer:navReducer,
//   courseReducer:courseReducer,
//   ratingReducer:ratingReducer,
//   userReducer:userReducer,

  // Add more reducers if needed
});

export default rootReducer;