import React from "react";
import truckImg from "../../assets/LandingImage/truckImg.png";
import InfoGraphic from "../../assets/LandingImage/InfoGraphic.png";
import noteBook from "../../assets/LandingImage/notebook_light.png";
import message from "../../assets/LandingImage/message-text.png";
import "./Banner.scss";
import { useNavigate } from "react-router";

const Banner = () => {
  const navigate = useNavigate()
  const handleKnowMore = (key) => {
    navigate(key)
  };

  return (
    <div className="banner">
      <div className="bannerContent">
        <h1 className="contentHeading">
          Online Portable Nuclear Gauge Training
        </h1>
        <p className="bannerText">
          A better training experience. Designed by gauge operators and
          regulatory licensing specialists, for gauge operators and RSOs.
        </p>
        <ul>
          <li>All density gauge models</li>
          <li>Accepted in every state</li>
          <li>Get certified now!</li>
        </ul>
        <button className="bannerBtn" onClick={() => handleKnowMore("/courses")}>
          Explore Courses
        </button>
        <div className="icons">
          <div className="icon">
            <img src={InfoGraphic} width={20} alt="InfoGraphic" />
            <span className="iconText">&nbsp; InfoGraphic</span>
          </div>
          <div className="border"></div>
          <div className="icon">
            <img src={message} width={20} alt="Reference Card" />
            <span className="iconText">&nbsp; Reference Card</span>
          </div>
          <div className="border"></div>
          <div className="icon">
            <img src={noteBook} width={20} alt="Courses" />
            <span className="iconText">&nbsp; Courses</span>
          </div>
        </div>
      </div>
      <div className="backgound">
        <img src={truckImg} className="truckImg" alt="Truck" />
      </div>
    </div>
  );
};

export default Banner;
