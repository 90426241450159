import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message } from "antd";
import { useNavigate } from "react-router";
import { MdOutlineEmail } from "react-icons/md";
import { SlLockOpen } from "react-icons/sl";
import { FaUserAlt } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import SignUpImg from "../../assets/SignupImg.png";
import CustomButton from "../CustomButton/CustomButton";
import Logo from "../../assets/Logo.png";
import { SignUpAPI } from "../../api's/Api";
import "./SignUp.scss";

const SignUp = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const usertype = localStorage.getItem("myValue");
  const [selectedRole, setSelectedRole] = useState("User");
  const [roleID, setRoleID] = useState("6");

  const onFinish = (values) => {
    const headers = {
      role_id: roleID,
    };
    const formData = {
      name: values.fullname,
      email: values.email,
      mobile: values.phone,
      password: values.password,
      confirm_password: values.confirmPassword,
    };

    SignUpAPI(formData, headers)
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.message);
          navigate("/verifyotp", { state: formData.email });
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log("error", e);
        // message.error(e.response.data.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setSelectedRole(e.target.value);
    setRoleID(e.target.value === "User" ? "6" : "7"); // Set role_id based on selected role
  };

  return (
    <div className="signup">
      <Row className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={SignUpImg} alt="login" className="loginImg" />
            </div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img src={Logo} width={200} alt="logo" />
            <h1 className="heading">Sign Up</h1>
            <p className="para">Enter your details to get started</p>
            <div className="form">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Full Name"
                  name="fullname"
                  rules={[
                    { required: true, message: "Please input your full name!" },
                  ]}
                >
                  <Input
                    className="input_custom"
                    placeholder="Full Name"
                    prefix={<FaUserAlt size={18} />}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input
                    className="input_custom"
                    placeholder="Email"
                    prefix={<MdOutlineEmail />}
                  />
                </Form.Item>
                <Form.Item
                  label="Phone Number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                    {
                      validator: (_, value) =>
                        value && value.length === 11
                          ? Promise.resolve()
                          : Promise.reject(
                              "Please input a valid US phone number!"
                            ),
                    },
                  ]}
                >
                  <PhoneInput
                    country={"us"}
                    onlyCountries={["us"]}
                    masks={{ us: "(...) ...-...." }}
                    inputStyle={{ width: "100%" }}
                    containerClass="input_custom"
                    placeholder="Phone Number"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    className="input_custom"
                    placeholder="Password"
                    prefix={<SlLockOpen />}
                  />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                  ]}
                >
                  <Input.Password
                    className="input_custom"
                    placeholder="Confirm Password"
                    prefix={<SlLockOpen />}
                  />
                </Form.Item>
                <CustomButton
                  htmlType="submit"
                  text="Sign Up"
                  bgColor="#3D3C6E"
                  width="100%"
                />
              </Form>
            </div>
            <div className="bottom_text">
              Already have an account?{" "}
              <span
                onClick={() => navigate("/login")}
                style={{ cursor: "pointer", color: "#3D3C6E" }}
              >
                Login
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SignUp;
