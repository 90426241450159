import React from "react";
import nodataavailable from "../../assets/nodataavailable.jpg";
import "./NoDataAvailable.scss"
const NoDataAvailable = ({ message }) => {
  return (
    <div className="no-data-available">
      <img src={nodataavailable} alt="No Data Available" />
      <h5>{message}</h5>
    </div>
  );
};

export default NoDataAvailable;
