import React, { useState, useEffect, useMemo } from "react";
import { Container, Form } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";
import { BsXLg } from "react-icons/bs";
import { Col, message, Row } from "antd";
import { GetQizeById, SubmitTest } from "../../api's/Api";
import "./Quiz.scss";
import { useNavigate, useParams } from "react-router";
import Loader from "../Loader/Loader";

const Quiz = () => {
  const [answers, setAnswers] = useState({});
  const [quizData, setQuizData] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [attempts, setAttempts] = useState(0); // Track the number of attempts
  const { id } = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");

  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  useEffect(() => {
    GetQizeById(id)
      .then((res) => {
        // console.log("res",res.data.data)
        if (res.data.success) {
          setQuizData(res.data.data);
          const durationInSeconds =
            parseInt(res.data.data.duration.split(" ")[0]) * 60;

          // Retrieve remaining time from sessionStorage or set initial duration
          const storedTime = sessionStorage.getItem(`timeRemaining_${id}`);
          setTimeRemaining(
            storedTime ? parseInt(storedTime) : durationInSeconds
          );

          // Set attempts
          const userAttempts = res.data.data.user_attempts || 0; // Assuming API returns attempt count
          setAttempts(userAttempts);

          // Initialize answers state based on the number of questions
          const initialAnswers = res.data.data.test_questions.reduce(
            (acc, question) => {
              acc[question.id] = null;
              return acc;
            },
            {}
          );
          setAnswers(initialAnswers);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [id]);

  useEffect(() => {
    if (timeRemaining === null) return;

    if (timeRemaining === 0) {
      setIsTimeUp(true);
      handleSubmit(true); // Automatically submit when time is up
      return;
    }

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const newTime = prevTime - 1;
        sessionStorage.setItem(`timeRemaining_${id}`, newTime); // Update sessionStorage
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining, id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers({
      ...answers,
      [name]: parseInt(value),
    });
  };

  const handleSubmit = (isAutomatic = false) => {
    // Check if any question is unanswered
    const unansweredQuestions = Object.values(answers).some(
      (answer) => answer === null
    );

    // If it's a manual submission and there are unanswered questions
    if (!isAutomatic && unansweredQuestions) {
      message.warning(
        "Please answer all questions before submitting the quiz."
      );
      return; // Stop the form from being submitted
    }

    // Prepare data in the required format
    const formData = {
      test_id: quizData.id,
      answers: Object.keys(answers).map((question_id) => ({
        question_id: parseInt(question_id),
        user_answer_id: answers[question_id],
      })),
    };

    SubmitTest(formData, headers)
      .then((res) => {
        setResultData(res.data.data);
        sessionStorage.removeItem(`timeRemaining_${id}`); // Clear timer when quiz is submitted
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 400) {
          message.error("Submission failed. Please try again.");
        } else {
          console.log("error", error);
        }
      });

    console.log("Submitted formData:", formData);
  };

  if (!quizData) {
    return (
      <div className="quizLoading">
        <Loader />
        <p>Loading...</p>
        
      </div>
    );
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleBack = () => {
    navigate("/dashboard");
  };
  // console.log("attempts", attempts);
  return (
    <div className="quiz-container">
      <Container>
        {attempts >= 3 ? (
          <div className="attempt-limit-message">
            <h2>You have reached the maximum number of attempts (3).</h2>
            <p>Unfortunately, you cannot attempt this quiz anymore.</p>
          </div>
        ) : !resultData ? (
          <>
            <div className="header">
              <div className="d-flex">
                <BsXLg
                  style={{ cursor: "pointer" }}
                  size={22}
                  onClick={handleBack}
                />
                <h3 className="title">{quizData.name}</h3>
              </div>
              <div className="timer">Timer: {formatTime(timeRemaining)}</div>
            </div>
            <div className="attempt-count">
              <p>Attempt {attempts + 1} of 3</p>
            </div>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="test-form"
            >
              {quizData.test_questions.map((question) => (
                <Form.Group as={Row} className="mb-3" key={question.id}>
                  <Form.Label as="legend" column sm={12}>
                    {question.question}
                  </Form.Label>
                  {question.test_options.map((option) => (
                    <Col sm={10} lg={10} key={option.id}>
                      <div className="custom-radio">
                        <input
                          type="radio"
                          id={`q${question.id}-${option.id}`}
                          name={question.id.toString()}
                          value={option.id} // Use option ID as value
                          onChange={handleChange}
                        />
                        <label htmlFor={`q${question.id}-${option.id}`}>
                          {option.option}
                        </label>
                      </div>
                    </Col>
                  ))}
                </Form.Group>
              ))}
              <CustomButton
                text="Submit"
                bgColor="white"
                color="#3D3C6E"
                border="2px solid #3D3C6E"
              />
            </Form>
          </>
        ) : (
          <div className="resultContainer">
            <div className="resultCard">
              <h2>Quiz</h2>
              <p>
                Module {quizData.module_number} : {quizData.total_questions}{" "}
                questions
              </p>
              <p className="result-status">Successfully Completed the quiz</p>
              <p className="result-score">Score</p>
              <h1>{resultData.score}/100</h1>
              <div className="confetti">
                {/* You can either use a confetti library or create a simple confetti animation */}
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Quiz;
