import React, { useEffect, useMemo } from "react";
import { Form, Input, Row, Col, message } from "antd";
import "./ProfileForm.scss";
import CustomButton from "../CustomButton/CustomButton";
import { EditProfile } from "../../api's/Api";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/profileSlice";

const ProfileForm = () => {
  const [form] = Form.useForm();
  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");
  const dispatch = useDispatch();

  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  const { profile, loading } = useSelector((state) => state.profileSlice);

  useEffect(() => {
    dispatch(getProfile(headers));
  }, [dispatch,headers]);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue({
        fullName: profile.user_profile.name,
        email: profile.email,
        phoneNumber: profile.user_profile.mobile,
        accountType: profile.account_type,
      });
    }
  }, [profile, form]);

  const onFinish = async (values) => {
    const formData = {
      name: values.fullName,
      mobile: values.phoneNumber,
      account_type: values.accountType,
    };

    try {
      const res = await EditProfile(formData, headers);
      console.log("Profile updated successfully:", res);
      message.success("Profile updated successfully");
      dispatch(getProfile(headers)); 
    } catch (error) {
      console.log("Error updating profile:", error);
      message.error("Failed to update profile. Please try again.");
    }
  };

  return (
    <Form
      form={form}
      name="profile_form"
      layout="vertical"
      onFinish={onFinish}
      className="profile-form"
      initialValues={profile ? {
        fullName: profile?.user_profile?.name,
        email: profile?.email,
        phoneNumber: profile?.user_profile?.mobile,
        accountType: profile?.account_type,
      } : {}}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="fullName"
            label="Full Name"
            rules={[
              { required: true, message: "Please input your full name!" },
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input placeholder="Email" disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input placeholder="Phone Number" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="accountType"
            label="Account Type"
            rules={[
              { required: true, message: "Please input your account type!" },
            ]}
          >
            <Input placeholder="Account Type (e.g., admin, user, guest)" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <CustomButton text="Edit Profile" bgColor="#3D3C6E" htmlType="submit" />
      </Form.Item>
    </Form>
  );
};

export default ProfileForm;
