import React from "react";
import Banner from "../Banner/Banner";
import AboutUs from "../AboutUs/AboutUs";
import LandingCard from "../LandingCard/LandingCard";

import icon from "../../assets/LandingImage//icon.png";
import image1 from "../../assets/LandingImage/image1.png";
import CardImage from "../../assets/LandingImage/cardimages.png";
import section4 from "../../assets/LandingImage/section4.png";
import image2 from "../../assets/LandingImage/image2.png";
import Image3 from "../../assets/LandingImage/image3.png";
import { Button, Container } from "react-bootstrap";
import "./Landing.scss";
import { useNavigate } from "react-router";

const Landing = () => {
const navigate = useNavigate()
  const handleKnowMore = (key)=>{
    navigate(key)
  }
  return (
    <div className="LandingPage">
      <div className="bannerSection">
        <Banner />
      </div>
      <div className="AboutUs">
        <AboutUs />
      </div>
      <div className="section3">
        <Container>
          <p className="small">Complete Entirely On Your Cellphone!</p>
          <div className="d-flex align-items-center w-100 justify-content-center">
            <h2 className="text">
              Gauge Operators are on the road. Nuclear Gauges USA courses go
              with them.
            </h2>
          </div>
          <div className="aboutContainer">
            <div className="imageContainer">
              <img src={section4} className="aboutImage" alt="section 4" />
            </div>
            <div className="content">
              <div className="d-flex mt-4">
                <img src={icon} className="arrowIcon" alt="Icon" />
                <p style={{ marginLeft: "10px" }}>
                  Many of our clients find that gauge operators can complete all
                  their training out of the office and during job-site downtime.
                  Same goes for our Gauge Operator Weekly Refresher Training –
                  bite-sized learning completed in a few minutes, on a
                  cellphone, once a week. If you want measurable training
                  retention and great ROI, you need the weekly refresher.
                </p>
              </div>
              <div className="d-flex">
                <img src={icon} className="arrowIcon" alt="Icon" />
                <p style={{ marginLeft: "10px" }}>
                  Portable Nuclear Gauge Operators often have significant
                  downtime while waiting for site preparation, and even
                  uncooperative weather, when completing required shots. Ours
                  are optimized for mobile device delivery – mobile phones and
                  tablets.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="section5">
        <Container>
          <LandingCard />
        </Container>
      </div>
      <div className="section6">
        <div className="d-flex align-items-center w-100 justify-content-center">
          <h2 className="text">
            All Courses are Built to Next Level with Industry Consensus
            Standards
          </h2>
        </div>
        <Container className="custom-container">
          <img src={image1} className="saftyPractice" alt="Safety Practice" />
          <p style={{ margin: "20px" }}>
            Our training courses take your organization’s safety program to the
            next level with industry consensus standards that promote safe work
            environments, improve productivity, and drive continuous
            improvement. We build our course adhering to criteria of ANSI/ASSP
            Z490.2-2019 Accepted Practices for E-Learning in Safety, Health, and
            Environmental Training.
          </p>
        </Container>

        <div className="videoContainer">
          <div className="contentContainer">
            <div>
              <img src={CardImage} alt="video" className="image" />
              <Container className="d-flex flex-column justify-content-center align-items-center">
                <div
                  style={{ color: "white", width: "72%", margin: "50px auto" }}
                >
                  <h4 style={{ margin: "20px auto" }}>
                    Nuclear Gauges USA is Reinventing Nuclear Safety Compliance.
                    We are working to make nuclear gauge compliance easier &
                    measurable for RSOs and Safety Managers.
                  </h4>
                  <p style={{ margin: "30px auto" }}>
                    Our training courses take your organization’s safety program
                    to the next level with industry consensus standards that
                    promote safe work environments, improve productivity, and
                    drive continuous improvement. We build our course adhering
                    to criteria of ANSI/ASSP Z490.2-2019 Accepted Practices for
                    E-Learning in Safety, Health, and Environmental Training.
                  </p>
                </div>
              </Container>
            </div>
          </div>
        </div>

        <div className="mobileVideoContainer">
          <div className="mobileContentContainer">
            <div>
              <img src={CardImage} alt="video" className="mobileImage" />
              <Container className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="mobile-heading">
                  Nuclear Gauges USA is Reinventing Nuclear Safety Compliance.
                  We are working to make nuclear gauge compliance easier &
                  measurable for RSOs and Safety Managers.
                </h4>
                <div className="d-flex flex-column justify-content-center">
                  <p className="mobile-paragraph">
                    Our training courses take your organization’s safety program
                    to the next level with industry consensus standards that
                    promote safe work environments, improve productivity, and
                    drive continuous improvement. We build our course adhering
                    to criteria of ANSI/ASSP Z490.2-2019 Accepted Practices for
                    E-Learning in Safety, Health, and Environmental Training.
                  </p>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>

      <Container className="mt-5">
      <div className="section7">
        <div className="gridContainer">
          <div className="gridItem">
            <div className="ImageSection">
              <img src={image2} alt="Description" className="image" />
            </div>
            <div className="column">
              <h4 className="headingText">
                All Courses are Built to Next Level with Industry Consensus Standards
              </h4>
              <p>
                Our training courses take your organization’s safety program to
                the next level with industry consensus standards that promote
                safe work environments, improve productivity and drive continuous
                improvement. We build our course adhering to criteria of ANSI/ASSP
                Z490.2-2019 Accepted Practices for E-Learning in Safety, Health and
                Environmental Training. Industry consensus standards that promote
                safe work environments, improve productivity and drive continuous
                improvement.
              </p>
              <div className="mt-5">
                <Button className="exploreBtn" onClick={()=>handleKnowMore("/courses")}>
                  Know More
                </Button>
              </div>
            </div>
          </div>

          <div className="gridItem reverse">
            <div className="column">
              <h4 className="headingText">
                Nuclear Gauges USA is Reinventing Nuclear Safety Compliance
              </h4>
              <p>
                Nuclear Gauges USA is working on some innovative initiatives
                to ease the tasks of the Radiation Safety Officer (RSO) and
                enhance regulatory compliance. Subscribe to our newsletter
                and we’ll keep you up-to-date on our progress.
              </p>
              <div className="mt-5">
                <Button className="exploreBtn" onClick={()=>handleKnowMore("/courses")}>
                  Know More
                </Button>
              </div>
            </div>
            <div className="ImageSection">
              <img src={Image3} alt="Description" className="image" />
            </div>
          </div>
        </div>
      </div>
    </Container>
    </div>
  );
};

export default Landing;
