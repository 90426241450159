import React, { useEffect, useMemo, useState } from "react";
import { Tabs } from "antd";
import { Container } from "react-bootstrap";
import VideoCard from "../VideoCard/VideoCard";
import { DashBoardItems } from "../../api's/Api";
import { useNavigate } from "react-router";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import Loader from "../Loader/Loader";
import "./Dashboard.scss";

const Dashboard = () => {
  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");
  const [courses, setCourses] = useState([]);
  const [tests, setTests] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingTests, setLoadingTests] = useState(true);
  const navigate = useNavigate();

  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Courses",
      children: loadingCourses ? (
        <div className="loader">
        
        <Loader /> 
        </div>
        
      ) : courses.length > 0 ? (
        <VideoCard cardData={courses} />
      ) : (
        <NoDataAvailable message="No courses available" />
      ),
    },
    {
      key: "2",
      label: "Tests",
      children: loadingTests ? (
        <Loader  className="loader"/> 
      ) : tests.length > 0 ? (
        <VideoCard cardData={tests} />
      ) : (
        <NoDataAvailable message="No tests available" />
      ),
    },{
      key:"3",
      label:"Certificate",
      children:"Certificates..."
    }
  ];

  useEffect(() => {
    const fetchCourses = () => {
      const type = "course";
      DashBoardItems(type, headers)
        .then((res) => {
          // console.log("res",res)
          if (res.data.success) {
            const fetchedCourses = res.data.data.data
              .filter((item) => item.dashboard_course)
              .map((item) => ({
                image: item.dashboard_course?.course_attachments[0]?.file_name,
                title: item.dashboard_course.name,
                rating: item.dashboard_course.average_rating,
                button_status: "Continue Learning",
                type: type,
                course_id: item.dashboard_course.id,
                progress: item.progress, 
                navigateLink: `/courseongoing/${item.dashboard_course.id}`,
                handleClick: handleNavigate,
                dashboard:"dashboard"
              }));
            setCourses(fetchedCourses);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoadingCourses(false);
        });
    };

    const fetchTests = () => {
      const type = "test";
      DashBoardItems(type, headers)
        .then((res) => {
          if (res.data.success) {
            const fetchedTests = res?.data?.data?.data
              .filter((item) => item?.dashboard_test)
              .map((item) => ({
                image: item.dashboard_test?.test_attachments[0]?.file_name,
                title: item.dashboard_test.name,
                questions: item.dashboard_test.question_count,
                minutes: item.dashboard_test.duration,
                rating: 0,
                button_status: "Start Test",
                type: type,
                test_id: item.dashboard_test.id,
                navigateLink: `/quiz/${item.dashboard_test.id}`,
                link: `/test/${item.dashboard_test.id}`,
                handleClick: handleNavigate,
              }));
            setTests(fetchedTests);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoadingTests(false); 
        });
    };

  
    const loadingTimer = setTimeout(() => {
      setLoadingCourses(false);
      setLoadingTests(false);
    }, 5000);

    fetchCourses();
    fetchTests();

 
    return () => clearTimeout(loadingTimer);
  }, [headers]);

  const handleNavigate = (id, type, navigateLink) => {
    navigate(navigateLink);
  };

  return (
    <Container>
      <div className="dashboard-course">
        <h3>Dashboard</h3>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </Container>
  );
};

export default Dashboard;
