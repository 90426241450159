import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetReviewandRatingsApi } from "../api's/Api";



export const getReviewAndRatings = createAsyncThunk(
    'review/getReviewAndRatings',
    async ({ formData, headers }, { rejectWithValue }) => {
      try {
        const response = await GetReviewandRatingsApi(formData, headers);
        return response.data.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

const reviewRatingSlice = createSlice({
  name: 'review',
  initialState: {
    reviews: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviewAndRatings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReviewAndRatings.fulfilled, (state, action) => {
        state.loading = false;
        state.reviews = action.payload;
      })
      .addCase(getReviewAndRatings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default reviewRatingSlice.reducer;
