import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Tabs, message } from "antd";
import VideoCard from "../VideoCard/VideoCard";
import {
  GetAllCoursesAPI,
  GetAllCoursesByToken,
  GetAllTestByToken,
  GetAllTestsAPI,
} from "../../api's/Api";
import { PostAddToCart } from "../../api's/Api";
import { useNavigate } from "react-router-dom";
import "./Courses.scss";

const Courses = () => {
  const [getAllCourses, setGetAllCourses] = useState([]);
  const [getAllTests, setGetAllTests] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [loadingTests, setLoadingTests] = useState(true);
  const [loadingItem, setLoadingItem] = useState(null);

  const navigate = useNavigate();
  const onChange = (key) => {
    console.log(key);
  };

  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");

  const headers = {
    "x-access-token": token,
    role_id: role_id,
  };
  const handleAddToCart = (id, type, navigateLink) => {
    setLoadingItem(id); 

    let formData;
    if (type === "course") {
      formData = {
        course_id: id,
      };
    }
    if (type === "test") {
      formData = {
        test_id: id,
      };
    }

    return PostAddToCart(formData, headers)
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.message);
          setTimeout(() => {
            navigate(navigateLink);
          }, 2000);
        }
      })
      .catch((e) => {
        console.log("error", e);
        message.error("Failed to add to cart.");
      })
      .finally(() => {
        setLoadingItem(null); // Clear the loading state once the operation is complete
      });
  };

  const formatCourseData = (data) => {
    return data.map((course) => ({
      image: course.course_attachments[0].file_name,
      course_id: course.course_attachments[0].course_id,
      title: course.name,
      lectures: course.lecture_count,
      months: `${course.lecture_count} months`,
      rating: parseFloat(course.average_rating) || 0,
      price: `$${course.price}`,
      button_status:
        course.is_purchased === false ? "Add To Cart" : "Continue Learning",
      type: "course",
      link: `/coursedetails/${course.course_attachments[0].course_id}`,
      navigateLink:
        course.is_purchased === false
          ? "/shopingcart"
          : `/courseongoing/${course.course_attachments[0].course_id}`,
      handleClick: handleAddToCart,
      is_purchased: course.is_purchased,
      role_id:role_id
    }));
  };

  const formatTestData = (data) => {
    return data
      .map((test) => {
        if (test.test_attachments && test.test_attachments.length > 0) {
          return {
            image: test.test_attachments[0].file_name,
            test_id: test.test_attachments[0].test_id,
            title: test.name,
            questions: test.question_count,
            minutes: test.duration,
            rating: parseFloat(test.average_rating) || 0,
            price: `$${test.price}`,
            button_status:
              test.is_purchased === false ? "Add To Cart" : "Start Test",
            type: "test",
            navigateLink:
              test.is_purchased === false
                ? "/shopingcart"
                : `/quiz/${test.test_attachments[0].test_id}`,
            handleClick: handleAddToCart,
            is_purchased: test.is_purchased,
            role_id:role_id
          };
        } else {
          return null;
        }
      })
      .filter((test) => test !== null);
  };

  useEffect(() => {
    if (token) {
      setLoadingCourses(true);
      GetAllCoursesByToken(headers)
        .then((res) => {
          const data = res?.data?.data;
          if (Array.isArray(data)) {
            const formattedData = formatCourseData(data);
            setGetAllCourses(formattedData);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoadingCourses(false);
        });
    } else {
      setLoadingCourses(true);
      GetAllCoursesAPI()
        .then((res) => {
          const data = res?.data?.data;
          if (Array.isArray(data)) {
            const formattedData = formatCourseData(data);
            setGetAllCourses(formattedData);
          }
        })
        .catch((e) => {
          console.log("error", e);
        })
        .finally(() => {
          setLoadingCourses(false);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      setLoadingTests(true);
      GetAllTestByToken(headers)
        .then((res) => {
          const data = res?.data?.data;
          if (Array.isArray(data)) {
            const formattedData = formatTestData(data);
            setGetAllTests(formattedData);
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoadingTests(false);
        });
    } else {
      setLoadingTests(true);
      GetAllTestsAPI()
        .then((res) => {
          const data = res?.data?.data;
          if (Array.isArray(data)) {
            const formattedData = formatTestData(data);
            setGetAllTests(formattedData);
          }
        })
        .catch((e) => {
          console.log("error", e);
        })
        .finally(() => {
          setLoadingTests(false);
        });
    }
  }, [token]);

  const items = [
    {
      key: "1",
      label: "Courses",
      children: (
        <VideoCard
          cardData={getAllCourses}
          loading={loadingCourses}
          loadingItem={loadingItem}
        />
      ),
    },
    {
      key: "2",
      label: "Tests",
      children: (
        <VideoCard
          cardData={getAllTests}
          loading={loadingTests}
          loadingItem={loadingItem}
        />
      ),
    },
  ];

  return (
    <Container>
      <div className="course">
        <h3>Shop</h3>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </Container>
  );
};

export default Courses;
