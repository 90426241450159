import React, { useEffect, useMemo, useState } from "react";
import { Progress, List, Drawer, Button, Col, Row } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./CourseOngoing.scss";
import { Container, Form } from "react-bootstrap";
import CustomButton from "../CustomButton/CustomButton";
import {
  GetFindLecturesById,
  GetLecturesList,
  GetProgress,
  GetQuestionsByLectureId,
  LectureMarkAsComplete,
  PostAnswersByLectureId,
} from "../../api's/Api";
import ContentComponent from "./ContentComponent";
import { useParams } from "react-router";
import Result from "./Result";

const CourseOngoing = () => {
  const [lecturesList, setLecturesList] = useState([]);
  const [courseTitle, setCourseTitle] = useState("");
  const [selectedLecture, setSelectedLecture] = useState(null);
  const [lectureContent, setLectureContent] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const { id } = useParams();

  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");

  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  useEffect(() => {
    GetLecturesList(id, headers)
      .then((res) => {
        setCourseTitle(res?.data?.data?.name || "");
        setLecturesList(res?.data?.data?.course_lectures || []);
        if (res?.data?.data?.course_lectures.length > 0) {
          setSelectedLecture(res?.data?.data?.course_lectures[0]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    progressBar(id, headers);
  }, [id, headers]);

  const progressBar = () => {
    GetProgress(id, headers)
      .then((res) => {
        const courseProgress = res.data.data.progress || 0;
        setProgress(courseProgress);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (selectedLecture) {
      GetFindLecturesById(selectedLecture.id)
        .then((res) => {
          setLectureContent(res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
        });

      GetQuestionsByLectureId(selectedLecture.id)
        .then((res) => {
          setQuestions(res.data.data.lecture_quiz);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [selectedLecture]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const handleLectureClick = (lecture) => {
    setSelectedLecture(lecture);
    setDrawerVisible(false);
  };

  const handleChange = (e, questionIndex, answerId) => {
    const { name, value } = e.target;
    const selectedAnswerData = questions[questionIndex].quiz_answers.find(
      (answer) => answer.id === parseInt(value)
    );
    const isCorrect = selectedAnswerData.correct;

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: value,
    }));

    setSelectedAnswer((prevSelectedAnswer) => ({
      ...prevSelectedAnswer,
      [questionIndex]: {
        id: answerId,
        correct: isCorrect,
      },
    }));

    // If the selected answer is wrong, show the correct answer
    if (!isCorrect) {
      questions[questionIndex].quiz_answers.forEach((answer) => {
        if (answer.correct) {
          document
            .querySelector(`#q${questionIndex + 1}-${answer.id}`)
            .closest(".custom-radio")
            .classList.add("showCorrectAnswer");
        }
      });
    }
  };

  const [quizResults, setQuizResults] = useState([]);
  const [showScoreboard, setShowScoreboard] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedAnswers = Object.keys(answers).map((key) => {
      const questionIndex = parseInt(key.replace("q", ""), 10) - 1;
      return {
        question_id: questions[questionIndex].id,
        user_answer_id: parseInt(answers[key], 10),
      };
    });

    const formData = {
      lecture_id: selectedLecture.id,
      answers: formattedAnswers,
    };

    PostAnswersByLectureId(formData, headers)
      .then((res) => {
        console.log("Quiz submitted successfully:", res);
        setQuizResults([res.data.data]);
        setShowScoreboard(true);
      })
      .catch((error) => {
        console.log("Error submitting quiz:", error);
      });
  };

  const currentLevel =
    lecturesList.findIndex((lecture) => lecture.id === selectedLecture?.id) + 1;
  const totalLevels = lecturesList.length;

  const handleContinue = () => {
    const formData = {
      course_id: id,
      lecture_id: selectedLecture.id,
    };

    LectureMarkAsComplete(formData, headers)
      .then((res) => {
        if (res.data && res.data.success) {
          progressBar(id, headers);
          setShowScoreboard(false);
        }
        const currentIndex = lecturesList.findIndex(
          (lecture) => lecture.id === selectedLecture.id
        );

        const updatedLecturesList = lecturesList.map((lecture, index) =>
          index === currentIndex ? { ...lecture, is_completed: true } : lecture
        );

        setLecturesList(updatedLecturesList);

        if (currentIndex < lecturesList.length - 1) {
          setSelectedLecture(lecturesList[currentIndex + 1]);
          setSelectedAnswer({});
          setAnswers({});
        } else {
          console.log("No more lectures to continue.");
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const restartQuiz = async () => {
    setSelectedAnswer({});
    setAnswers({});
    setQuizResults(null);
    setShowScoreboard(false);

    if (selectedLecture) {
      try {
        const res = await GetQuestionsByLectureId(selectedLecture.id);
        setQuestions(res.data.data.lecture_quiz);
      } catch (error) {
        console.log("Error fetching questions:", error);
      }
    }
  };

  return (
    <Container>
      <div className="course-ongoing">
        <Button
          className="menu-button"
          icon={<MenuOutlined />}
          onClick={showDrawer}
        />
        <Drawer
          title={courseTitle}
          placement="left"
          closable={true}
          onClose={onClose}
          open={drawerVisible}
        >
          <div className="progress">
            <Progress percent={progress} showInfo={false} />
            <span>{progress}% Completed</span>
          </div>
          <List
            dataSource={lecturesList}
            renderItem={(item, index) => (
              <List.Item
                className={`lecture-item ${item.completed ? "completed" : ""} ${
                  item.id === selectedLecture?.id ? "active" : ""
                }`}
                onClick={() => handleLectureClick(item)}
              >
                {index + 1}. {item.name}
                {item.completed && (
                  <span className="completed-badge">Completed</span>
                )}
              </List.Item>
            )}
          />
        </Drawer>
        <div className="sidebar">
          <h2>{courseTitle}</h2>
          <p style={{ marginBottom: "0px" }}>{progress}% Completed</p>
          <div className="progress">
            <Progress percent={progress} showInfo={false} />
          </div>
          <List
            dataSource={lecturesList}
            renderItem={(item, index) => (
              <List.Item
                className={`lecture-item ${
                  item.is_completed ? "completed" : ""
                } ${item.id === selectedLecture?.id ? "active" : ""}`}
                onClick={() => handleLectureClick(item)}
              >
                {index + 1}. {item.name}
                {item.is_completed && (
                  <span className="completed-badge">Completed</span>
                )}
              </List.Item>
            )}
          />
        </div>
        <div className="content">
          <h3>
            Level {currentLevel} of {totalLevels}
          </h3>
          {selectedLecture?.type === "Text" && (
            <>
              <h1>{lectureContent?.name}</h1>

              <ContentComponent htmlContent={lectureContent?.content} />
            </>
          )}
          {selectedLecture?.type === "Quiz" && (
            <div className="test-knowledge">
              <h2>Test Your Knowledge</h2>
              <Form onSubmit={handleSubmit} className="test-form">
                {questions.map((question, index) => (
                  <Form.Group as={Row} className="mb-3" key={question.id}>
                    <Form.Label as="legend" column sm={12}>
                      {question.question}
                    </Form.Label>
                    <Col sm={8} lg={24}>
                      {question.quiz_answers.map((answer) => {
                        const isSelected =
                          selectedAnswer[index]?.id === answer.id;
                        const isCorrect = answer.correct;

                        return (
                          <div
                            className={`custom-radio ${
                              isSelected
                                ? isCorrect
                                  ? "correctAnswer"
                                  : "wrongAnswer"
                                : selectedAnswer[index] && isCorrect
                                ? "showCorrectAnswer"
                                : ""
                            }`}
                            key={answer.id}
                          >
                            <input
                              type="radio"
                              id={`q${index + 1}-${answer.id}`}
                              name={`q${index + 1}`}
                              value={answer.id}
                              checked={
                                answers[`q${index + 1}`] === String(answer.id)
                              } // Match value with state
                              onChange={(e) =>
                                handleChange(e, index, answer.id)
                              }
                              disabled={selectedAnswer[index] !== undefined}
                            />
                            <label
                              htmlFor={`q${index + 1}-${answer.id}`}
                              className={
                                isSelected
                                  ? isCorrect
                                    ? "correctAnswer"
                                    : "wrongAnswer"
                                  : selectedAnswer[index] &&
                                    isCorrect &&
                                    "correctAnswer"
                              }
                            >
                              {answer.option}
                            </label>
                          </div>
                        );
                      })}
                    </Col>
                  </Form.Group>
                ))}

                <CustomButton
                  text="Submit"
                  bgColor="white"
                  color="#3D3C6E"
                  border="2px solid #3D3C6E"
                />
              </Form>
            </div>
          )}

          <div className="btn-container">
            <CustomButton text="Continue" onClick={handleContinue} />
          </div>
        </div>
        {showScoreboard && quizResults && (
          <Result
            key={Date.now()} // Force remount
            isVisible={showScoreboard}
            onClose={() => setShowScoreboard(false)}
            quizResults={quizResults}
            restartQuiz={restartQuiz}
            handleContinue={handleContinue}
          />
        )}
      </div>
    </Container>
  );
};

export default CourseOngoing;
