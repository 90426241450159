import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap";
import { FaShoppingCart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Space } from "antd";
import { useSelector } from "react-redux";
import logo from "../../assets/Logo.png";
import CustomButton from "../CustomButton/CustomButton";
import "./NavigationBar.scss";

const NavigationBar = () => {
  const [activeLink, setActiveLink] = useState("");
  const token = localStorage.getItem("userToken");

  const { cart, error } = useSelector((state) => state.cartReducer);

  const navigate = useNavigate();

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const handleNavigate = (key) => {
    navigate(key);
  };

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("myValue");
    navigate("/");
  };

  return (
    <Navbar expand="lg" className="navbar">
      {token ? (
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="150"
              className="d-inline-block align-top"
              alt="Nuclear Gauges USA Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link
                className={activeLink === "home" ? "active" : ""}
                onClick={() => {
                  handleLinkClick("home");
                  handleNavigate("/");
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={activeLink === "dashboard" ? "active" : ""}
                onClick={() => {
                  handleLinkClick("dashboard");
                  handleNavigate("/dashboard");
                }}
              >
                Dashboard
              </Nav.Link>
              <Nav.Link
                className={activeLink === "shop" ? "active" : ""}
                onClick={() => {
                  handleLinkClick("shop");
                  handleNavigate("/courses");
                }}
              >
                Shop
              </Nav.Link>
              <Nav.Link
                className={activeLink === "contact" ? "active" : ""}
                onClick={() => {
                  handleLinkClick("contact");
                  handleNavigate("/contactus");
                }}
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                className={activeLink === "shoppingcart" ? "active" : ""}
                onClick={() => {
                  handleNavigate("/shopingcart");
                }}
              >
                <Space>
                  <Badge
                    count={error ? null : cart?.cartItems?.length}
                    showZero
                  >
                    <FaShoppingCart size={20} />
                  </Badge>
                </Space>
              </Nav.Link>
              <NavDropdown title="Account" id="basic-nav-dropdown">
                <NavDropdown.Item
                  as={Link}
                  to="/profile"
                  className={activeLink === "profile" ? "active" : ""}
                  onClick={() => handleLinkClick("profile")}
                >
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      ) : (
        <Container>
          <Navbar.Brand onClick={() => handleNavigate("/")}>
            <img
              src={logo}
              width="150"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link className="mr-3" onClick={() => handleNavigate("/")}>
                Home
              </Nav.Link>
              <Nav.Link
                className="mr-3"
                onClick={() => handleNavigate("/courses")}
              >
                Shop
              </Nav.Link>
              <Nav.Link
                className="mr-3"
                onClick={() => handleNavigate("/contactus")}
              >
                Contact Us
              </Nav.Link>
              {/* <Button
                className={`ml-3 loginBtn`}
                onClick={() => handleNavigate("/login")}
              >
                Register / Login
              </Button> */}
              <CustomButton text="Register / Login" onClick={()=>handleNavigate("/login")}/>
            </Nav>
          </Navbar.Collapse>
        </Container>
      )}
    </Navbar>
  );
};

export default NavigationBar;
