import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Radio, Divider, message, Spin } from "antd";
import { useNavigate } from "react-router";
import loginImage from "../../assets/LoginImg.png";
import Logo from "../../assets/Logo.png";
import { MdOutlineEmail } from "react-icons/md";
import { SlLockOpen } from "react-icons/sl";
// import googleImg from "../../assets/google.png";
import "./Login.scss";
import { LoginAPI } from "../../api's/Api";
import CustomButton from "../CustomButton/CustomButton";

const Login = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [selectedRole, setSelectedRole] = useState("User");
  const [roleID, setRoleID] = useState("6");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onFinish = (values) => {
    setLoading(true); // Set loading to true when the form is submitted
    const headers = {
      timezone: "Asia/Kolkata",
      role_id: roleID,
    };
    const formData = {
      email: values.email,
      password: values.password,
    };

    LoginAPI(formData, headers)
      .then((res) => {
        setLoading(false); // Set loading to false when the response is received
        if (res.data.success) {
          message.success(res.data.message);
          localStorage.removeItem("myValue");

          localStorage.setItem("myValue", res?.data?.data?.role_id);
          let Token = res?.data?.data?.tokens?.access?.token;
          if (Token) {
            localStorage.removeItem("userToken");
            localStorage.setItem("userToken", Token);
            navigate("/courses");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        setLoading(false); // Set loading to false if an error occurs
        console.log("error", e);
        message.error(e.response.data.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    setSelectedRole(e.target.value);
    setRoleID(e.target.value === "User" ? "6" : "7");
  };

  return (
    <div className="login">
      <Row gutter={[16, 16]} className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={loginImage} alt="login" className="loginImg" />
              <div className="imageOverlay"></div>
            </div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img src={Logo} width={200} alt="logo" className="logo" />
            <h1 className="heading">Login</h1>
            <p className="para">Login with your email address & password</p>
            <div>
              <Radio.Group
                onChange={onChange}
                value={selectedRole}
                className="Radio_btn"
              >
                <Radio value="Corporate" className="input_custom">
                  Corporate
                </Radio>
                <Radio value="User" className="input_custom">
                  User
                </Radio>
              </Radio.Group>
            </div>
            <div className="form">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                >
                  <Input
                    className="login_input"
                    placeholder="Email"
                    prefix={<MdOutlineEmail />}
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    className="login_input"
                    placeholder="Password"
                    prefix={<SlLockOpen />}
                  />
                </Form.Item>
                <p
                  className="forgot_pass"
                  onClick={() => navigate("/forgotpassword")}
                >
                  Forgot Password?
                </p>

                <CustomButton
                  htmlType="submit"
                  text="Sign in"
                  bgColor="#3D3C6E"
                  width="100%"
                  loading={loading} // Pass the loading state to the button
                />
              </Form>
            </div>
          </div>
          {roleID !== "7" && (
            <div className="bottom_text">
              Don’t have an account?{" "}
              <span
                onClick={() => navigate("/signup")}
                style={{ cursor: "pointer", color: "#3D3C6E" }}
              >
                Sign Up
              </span>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Login;
