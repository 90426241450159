import axios from "axios";
const Base_URL = `https://node.nucleargauges.com:5000/api/v1/`;

//SignUp API

export const SignUpAPI = async (formData, headers) => {
  return await axios.post(`${Base_URL}user/auth/register`, formData, {
    headers,
  });
};

//Verify OTP API

export const VerifyOTPAPI = async (formData, headers) => {
  return await axios.post(`${Base_URL}user/auth/verify-otp`, formData, {
    headers,
  });
};

//Login API

export const LoginAPI = async (formData, headers) => {
  return await axios.post(`${Base_URL}user/auth/login`, formData, { headers });
};

//Contact Us API

export const ContactUsAPI = async (formData, headers) => {
  return await axios.post(`${Base_URL}contactUs/createContactUs`, formData, {
    headers,
  });
};

// Get All Courses
export const GetAllCoursesAPI = async () => {
  return await axios.get(`${Base_URL}course/getAllCourse`);
};

//Get All Course By Token
export const GetAllCoursesByToken = async (headers)=>{
  return await axios.get(`${Base_URL}course/getAllCourseByToken`,{headers});
}
//Get All Test By Token
export const GetAllTestByToken = async (headers)=>{
  return await axios.get(`${Base_URL}test/getAllTestByToken`,{headers});
}

//Get course By I'd
export const GetCourseById = async (id) => {
  return await axios.get(`${Base_URL}course/findCourseById/${id}`);
};
// Get All Courses
export const GetAllTestsAPI = async () => {
  return await axios.get(`${Base_URL}test/getAlltest`);
};

// Post FAQ
export const PostFAQApi = async (question, headers) => {
  return await axios.post(`${Base_URL}/forum/createQuestion`, question, {
    headers,
  });
};

// Get FAQ
export const GetFAQAPI = async () => {
  return await axios.get(`${Base_URL}forum/getAllQuestions`);
};

//Post Review and Ratings
export const PostReviewandRatingsApi = async (formData, headers) => {
  return await axios.post(`${Base_URL}rating/postReviewAndRating`, formData, {
    headers,
  });
};

//Get Review and Ratings
export const GetReviewandRatingsApi = async (formData, headers) => {
  return await axios.post(`${Base_URL}rating/getReviewsAndRatings`, formData, {
    headers,
  });
};

// Edit review and rating
export const EditReviewandRating = async (formData, headers) => {
  return await axios.post(`${Base_URL}rating/updateReviewAndRating`, formData, {
    headers,
  });
};

//Delete review and ratings
export const DeleteReviewandRating = async (id, headers) => {
  return await axios.delete(`${Base_URL}rating/deleteReviewAndRating/${id}`, {
    headers,
  });
};

//Post Cart Items
export const PostAddToCart = async (formData, headers) => {
  return await axios.post(`${Base_URL}cart/addToCart`, formData, { headers });
};

// Get Cart Items
export const GetCartItems = async (headers) => {
  return await axios.get(`${Base_URL}cart/getAllItemsInCart`, { headers });
};

// Buy Cart Items
export const BuyCartItem = async (headers) => {
  return await axios.post(`${Base_URL}cart/buyItemsInCart`, null, { headers });
};

//Delete Cart Item
export const DeleteCartItems = async (formData, headers) => {
  return await axios.post(`${Base_URL}cart/deleteItemFromCart`, formData, {
    headers,
  });
};
// Get Profile
export const GetProfile = async (headers) => {
  return await axios.get(`${Base_URL}user/profile`, { headers });
};

//Edit Profile Api
export const EditProfile = async (formData, headers) => {
  return await axios.put(`${Base_URL}user/updateUserProfile`, formData, {
    headers,
  });
};

export const DashBoardItems = async (type, headers) => {
  return await axios.post(`${Base_URL}cart/getAllItemsFromDashboard`, type, {
    headers,
  });
};

export const GetPurchaseHistory = async (headers) => {
  return await axios.get(`${Base_URL}cart/getPurchaseHistory`, { headers });
};

//Get Lecture List
export const GetLecturesList = async (id,headers) => {
  return await axios.get(`${Base_URL}course/getAllLecturesInCourse/${id}`,{headers});
};

// Get course Progress By Id
export const GetProgress = async (id,headers) => {
  return await axios.get(`${Base_URL}course/getCourseProgress/${id}`,{headers});
};

// Get Find Lecture By Id
export const GetFindLecturesById = async (id) => {
  return await axios.get(`${Base_URL}course/findLectureById/${id}`,);
};


//Get Question By Lecture Id
export const GetQuestionsByLectureId= async (id)=>{
  return await axios.get(`${Base_URL}quiz/getQuestionsByLectureId/${id}`);
}

//Post Answers By Lecutre Id
export const PostAnswersByLectureId= async (formData,headers)=>{
  return await axios.post(`${Base_URL}quiz/submitQuiz`,formData,{headers});
}

//Get Result By Id 
export const GetQuizResultById = async(headers,id)=>{
return await axios.get(`${Base_URL}quiz/getAnswerSheet/${id}`,{headers})
}

// Forgot Password apis
export const sendOtpAPi = async (formData, headers) => {
  return await axios.post(`${Base_URL}/user/auth/otp`, formData, { headers });
};

export const VerifyAPi = async (formData, headers) => {
  return await axios.post(`${Base_URL}/user/auth/verify-otp`, formData, {
    headers,
  });
};
// Forgot Password Api
export const ForgotPasswordApi = async (formData, headers) => {
  return await axios.post(`${Base_URL}/user/auth/forgot-password`, formData, {
    headers,
  });
};

//Get Company Name List
export const GetCompanyName = async () => {
  return await axios.get(`${Base_URL}/corporate/getAllCompanyName`);
};

//pOST share Data 
export const PostShareData = async (corporate_id, headers) => {
  return await axios.post(
    `${Base_URL}/corporate/shareData`,
    { corporate_id: corporate_id },
    { headers }
  );
};

// Share Data List 
export const SharedDataList = async (headers)=>{
  return await axios.get(`${Base_URL}corporate/getListOfSharedData`,{headers});
}

// Get Quiz By Id
export const GetQizeById = async (id)=>{
  return await axios.get(`${Base_URL}test/getQuestionsByTestId/${id}`);
}


//Lecture Mark As Complete
export const LectureMarkAsComplete = async (fommData,headers)=>{
return await axios.post(`${Base_URL}user/course/markLectureAsCompleted`,fommData,{headers})
}

// Submit test

export const SubmitTest = async (formData,headers) =>{
  return await axios.post(`${Base_URL}test/submitTest`,formData,{headers})
}

export const EndQuiz = async (formData,headers)=>{
  return await axios.post(`${Base_URL}test/submitTest`,formData,{headers})
}

export const SquareKey = async (headers)=>{
return await axios.get(`${Base_URL}payment/square/square-key`,{headers})
}

export const CreatePayment = (formData,headers)=>{
  return axios.post(`${Base_URL}payment/square/create-payment`,formData,headers)
}
export const GetAllCerficat = async (type,headers)=>{
  return await axios.post (`${Base_URL}cart/getAllCertificates`,{type},{headers})

}
