import React from 'react'
import mobileImage from "../../assets/LandingImage/mobile.png";
import saveTime from "../../assets/LandingImage/savetime.png";
import noHide from "../../assets/LandingImage/nohide.png";
import { Card } from 'antd';
import "./LandingCard.scss"
const LandingCard = () => {
    const cardData = [
        {
          image: mobileImage,
          title: "Mobile Friendly",
          description:
            "USA’s most complete gauge training on your preferred device – in the office, on the jobsite. Anytime, anywhere, you decide.",
        },
        {
          image: saveTime,
          title: "Save Time",
          description:
            "Our mobile courses save you significant time and money. Complete your training at home, at the office, at the hotel, or in the vehicle at the job site – anytime on any device",
        },
        {
          image: noHide,
          title: "No Hidden Fees",
          description:
            "Our approach means simple pricing for premiere portable moisture/density gauge training and resources for the gauge industry for one low price.",
        },
      ];
  return (
    <div className="landingCard">
      {cardData.map((item, index) => (
        <Card
          key={index}
          hoverable
          className="card"
          cover={<img alt="example" src={item.image} height={200}/>}
        >
          <div>
            <h6 className="title">{item.title}</h6>
            <p>{item.description}</p>
          </div>
        </Card>
      ))}
    </div>
  )
}

export default LandingCard