import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { GetCartItems } from "../api's/Api";

export const getCartItems = createAsyncThunk(
    'cart/getCartItems',
    async (headers, { rejectWithValue }) => {
  
      try {
        const response = await GetCartItems(headers);
        return response.data.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  const cartSlice = createSlice({
    name: 'cart',
    initialState: {
      cart: [],
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getCartItems.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getCartItems.fulfilled, (state, action) => {
          state.loading = false;
          state.cart = action.payload;
        })
        .addCase(getCartItems.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload || action.error.message;
        });
    },
  });   
  
  export default cartSlice.reducer;

