import React from "react";
import AboutImage from "../../assets/LandingImage/aboutImg.png";
import icon from "../../assets/LandingImage//icon.png";
import certified from "../../assets/LandingImage/certified.png";
import application from "../../assets/LandingImage/application.png";
import learning from "../../assets/LandingImage/learning.png";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./AboutUs.scss";

const AboutUs = () => {
  const navigate = useNavigate()
  const handleKnowMore = (key) => {
    navigate(key)
  };

  return (
  
      <Container>
        <div className="section1">
          <p className="small">About Us</p>
          <div className="d-flex align-items-center w-100 justify-content-center">
            <h2 className="aboutHeading">
              Nuclear Gauges USA, America's premier Portable Nuclear Gauge
              Operator Trainer
            </h2>
          </div>
          <div className="aboutContainer">
            <div className="imageContainer">
              <img src={AboutImage} className="aboutImage" alt="About Us" />
            </div>
            <div className="content">
              <h2 className="contentHeading">
                Working Safely With Portable Gauges
              </h2>
              <div className="aboutText">
                <img src={icon} className="arrowIcon" alt="Icon" />
                <p style={{ marginLeft: "10px" }}>
                  New to Nuclear Gauging? Need a quick refresher? Download our
                  Infographic & Get a Fast Overview of What You Require for
                  Regulatory Compliance including Licence Application, Training,
                  Transportation of Nuclear Gauges, Audits & Inspections
                </p>
              </div>
              <div className="aboutContaint">
                <img src={icon} className="arrowIcon" alt="Icon" />
                <p style={{ marginLeft: "10px" }}>
                  Already know what training you need. Review our Job
                  classification training reference card. Or go directly to
                  course catalog.
                </p>
              </div>
              <div className="aboutContaint">
                <img src={icon} className="arrowIcon" alt="Icon" />
                <p style={{ marginLeft: "10px" }}>
                  Most Portable Nuclear Gauge Licensees are looking for approved
                  training courses, help with applying for a new licence or
                  licence renewal & compliance resources. 90 percent or more of
                  what you need can be found by clicking on one of these 3
                  categories.
                </p>
              </div>
              <button
                className="knowMoreButton"
                onClick={() => handleKnowMore("/courses")}
              >
                Know More
              </button>
            </div>
          </div>
        </div>
        <div className="section2">
          <p className="small">Explore Our Program</p>
          <h2 style={{ textAlign: "center", color: "#3D3C6E" }}>
            Get Certified as Nuclear Gauge Operator Now
          </h2>
          <div className="certifiedSection">
            <img src={certified} alt="Certified" />
            <img
              src={application}
              style={{ margin: "auto 60px" }}
              alt="Application"
            />
            <img src={learning} alt="Learning" />
          </div>
        </div>
      </Container>

  );
};

export default AboutUs;
