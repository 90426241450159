import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import "./PurchaseHistory.scss";
import { GetPurchaseHistory } from "../../api's/Api";
import { IMAGE_URL } from "../../api's/Host";

const columns = [
  {
    title: 'Invoice ID',
    dataIndex: 'invoiceId',
    key: 'invoiceId',
  },
  {
    title: 'Items',
    dataIndex: 'item',
    key: 'item',
    render: (text, record) => (
      <div className="item-cell">
        <img src={record.avatar} alt={text} crossOrigin="anonymous" />
        <span>{text}</span>
      </div>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
];

const PurchaseHistory = () => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");

  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  useEffect(() => {
    GetPurchaseHistory(headers)
      .then((res) => {
        const fetchedData = res?.data?.data.map((item) => {
          const isCourse = item.purchase_history_course !== null;
          const isTest = item.purchase_history_test !== null;
          return {
            key: item.id,
            invoiceId: `#${item.order_id}`, // Prepending # to Invoice ID
            avatar: isCourse
              ? `${IMAGE_URL}/${item.purchase_history_course?.course_attachments[0]?.file_name}`
              : isTest
              ? `${IMAGE_URL}/${item.purchase_history_test?.test_attachments[0]?.file_name}`
              : '',
            item: isCourse
              ? item.purchase_history_course.name
              : isTest
              ? item.purchase_history_test.name
              : 'Unknown Item',
            date: new Date(item.date).toLocaleDateString(),
            quantity: item.quantity,
            amount: `$${item.amount}`,
          };
        });
        setData(fetchedData);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, [headers]);

  return (
    <div className="purchase-history">
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  );
};

export default PurchaseHistory;
