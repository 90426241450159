import React from "react";
import "./CustomButton.scss";

const CustomButton = ({
  text,
  onClick,
  bgColor,
  width,
  padding,
  margin,
  color,
  border,
  htmltype,
  loading // New prop for loading
}) => {
  return (
    <button
      className="customBtn"
      htmltype={htmltype}
      onClick={onClick}
      style={{
        backgroundColor: bgColor,
        width: width,
        padding: padding,
        margin: margin,
        color: color,
        border: border,
        position: "relative", // To position the spinner
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      disabled={loading} // Disable button when loading
    >
      {loading ? (
        <div className="spinner"></div>
      ) : (
        text
      )}
    </button>
  );
};

export default CustomButton;
