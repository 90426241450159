import React, { useEffect, useMemo, useState } from "react";
import { GetAllCerficat } from "../../api's/Api";
import "./CertificateList.scss";
import { Link } from "react-router-dom";

const CertificateList = () => {
  const [certificte, setCertificate] = useState([]);
  const token = localStorage.getItem("userToken");
  const role_id = localStorage.getItem("myValue");

  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id,
    }),
    [token, role_id]
  );

  useEffect(() => {
    const type = "course";
    GetAllCerficat(type, headers)
      .then((res) => {
        setCertificate(res?.data?.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [headers]);

  return (
    <div className="certificateList">
      {certificte?.map((cert) => (
        <Link
          to={{
            pathname: "/showcertificate",
          }}
          state={{ certificateData: cert }}
          className="link"
          key={cert.id}
        >
          <div className="certificateContent">
            <h5>{cert?.dashboard_course?.name}</h5>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default CertificateList;
